import React, { useContext, useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CartContext } from '../../Context/CardContext';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SeleccionMedioPago = ({
    medioPago,
    mediosPago,
    handleMedioPagoChange,
    carrito,
    precioTotal,
    costoEnvio,
    direccion,
    selectedComuna,
    selectedRegion,
    enabledField,
    rutEmpresa,
    razonSocial,
    giro,
    direccionEmpresa,
    facturacion,
    compra_id,
    nombre,
    rut,
    telefono,
    correo
}) => {
    const { vaciarCarrito } = useContext(CartContext);
    const [mensajeError, setMensajeError] = useState('');
    const [mensajeAdicional, setMensajeAdicional] = useState('');
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [transactionData, setTransactionData] = useState(null);
    const [isLoadingTransaction, setIsLoadingTransaction] = useState(false);
    const recaptchaRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (medioPago === 'WebPay') {
            setIsLoadingTransaction(true);
            handlePayClick();
        } else {
            setTransactionData(null);
        }
    }, [medioPago]);

    const verificarStockYVigencia = async () => {
        try {
            const response = await fetch('https://prosillas.cl/api/verificar_stock.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    productos: JSON.stringify(carrito.map(producto => ({
                        id_producto: producto.id,
                        cantidad: producto.cantidad
                    }))),
                }),
            });

            const result = await response.json();
            if (result.success) {
                return true;
            } else {
                const errores = result.resultados.map(producto => 
                    `El producto "${producto.titulo}" ${producto.razon}.`
                ).join('\n');
                setMensajeError(errores);
                return false;
            }
        } catch (error) {
            setMensajeError('Error de conexión al verificar el stock');
            return false;
        }
    };

    const handlePayClick = async () => {
        const stockValido = await verificarStockYVigencia();
        if (!stockValido) {
            setMensajeAdicional('Regresarás a la página de inicio');
            setTimeout(() => {
                vaciarCarrito();
                window.location.href = '/';
            }, 10000);
            return;
        }

        const productos = carrito.map(producto => ({
            id: producto.id,
            titulo: producto.titulo,
            precio: producto.precio,
            cantidad: producto.cantidad,
            coste_adicional: producto.cantidad === 1 ? 0 : (producto.coste_adicional || 0),
        }));

        const totalCosteAdicional = productos.reduce((total, producto) => total + producto.coste_adicional, 0);
        const totalAPagar = parseInt(precioTotal(), 10) + parseInt(costoEnvio, 10) + totalCosteAdicional;

        const paymentData = {
            compra_id,
            nombre: nombre,
            rut: rut,
            telefono: telefono,
            correo: correo,
            envio: enabledField,
            ...(enabledField !== 'Retiro' && {
                direccion,
                comuna: selectedComuna,
                region: selectedRegion,
            }),
            precio_total: precioTotal(),
            productos: productos,
            medioPago: medioPago,
            facturacion: facturacion,
            ...(facturacion && {
                rutEmpresa,
                razonSocial,
                giro,
                direccionEmpresa,
            }),
        };

        sessionStorage.setItem('paymentData', JSON.stringify(paymentData));

        // console.log('Datos almacenados en sessionStorage:', paymentData);

        if (medioPago === 'WebPay') {
            try {
                const requestData = {
                    buyOrder: `O-${Date.now()}`,
                    sessionId: `S-${Date.now()}`,
                    amount: totalAPagar,
                    returnUrl: 'https://prosillas.cl/redirector',
                };
                const response = await axios.post('https://prosillas.cl/api/Webpay/Webpay.php', requestData);
                setTransactionData(response.data);
                setIsLoadingTransaction(false);
                vaciarCarrito();
            } catch (error) {
                setMensajeError('Error al procesar el pago con Webpay.');
                setIsLoadingTransaction(false);
            }
        }else if (medioPago === 'Transferencia Bancaria') {
            vaciarCarrito();
            setTransactionData(null);
            navigate('/datos-transferencia');
        }else if (medioPago === 'Mercado Pago') {
            setTransactionData(null);
            try {
                const response = await fetch('https://prosillas.cl/api/mercadoPago/mercado_pago.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        title: 'Compra Prosillas',
                        price: totalAPagar,
                        quantity: 1,
                    }),
                });
                const result = await response.json();
                if (result.init_point) {
                    vaciarCarrito();
                    window.location.href = result.init_point;
                } else {
                    setMensajeError('Error al generar la preferencia de pago.');
                }
            } catch (error) {
                setMensajeError('Error de conexión con Mercado Pago.');
            }
        } else {
            vaciarCarrito();
            window.location.href = '/';
        }
    };

    const redirectToWebpay = () => {
        if (transactionData) {
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = transactionData.url;
            const tokenInput = document.createElement('input');
            tokenInput.type = 'hidden';
            tokenInput.name = 'token_ws';
            tokenInput.value = transactionData.token;
            form.appendChild(tokenInput);
            document.body.appendChild(form);
            form.submit();
        }
    };

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    return (
        <React.Fragment>
            <Typography variant="h2" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
                Seleccione su medio de pago
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: 2,
                    mx: 'auto',
                    maxWidth: 400,
                    width: '100%',
                }}
            >
                <Select
                    value={medioPago}
                    onChange={handleMedioPagoChange}
                    displayEmpty
                    fullWidth
                    sx={{ minWidth: 200, mb: 2 }}
                >
                    <MenuItem value="" disabled>
                        Seleccione medio de pago
                    </MenuItem>
                    {mediosPago.map((medio) => (
                        <MenuItem key={medio.id} value={medio.nombre}>
                            {medio.nombre}
                        </MenuItem>
                    ))}
                </Select>

                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '400px', margin: '0 auto', mb: 2 }}>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LdH4r4qAAAAANU3bMgG7BFiLRaMrGjfsdgAEWS-"
                        onChange={handleRecaptchaChange}
                    />
                </Box>

                {medioPago === 'WebPay' ? (
                    <Button
                        onClick={redirectToWebpay}
                        variant="contained"
                        color="primary"
                        sx={{ width: '100%' }}
                        disabled={!transactionData || isLoadingTransaction || !recaptchaValue}
                    >
                        {isLoadingTransaction ? 'Cargando...' : 'Pagar'}
                    </Button>
                ) : (
                    <Button
                        onClick={handlePayClick}
                        disabled={!medioPago || !recaptchaValue}
                        variant="contained"
                        sx={{ width: '100%' }}
                    >
                        Pagar
                    </Button>
                )}
                {mensajeError && (
                    <Typography variant="h6" color="error" sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
                        {mensajeError}
                    </Typography>
                )}
                {mensajeAdicional && (
                    <Typography variant="h6" sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
                        {mensajeAdicional}
                    </Typography>
                )}
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            mt: 2,
                            color: 'white',
                            backgroundColor: 'black',
                            padding: 1,
                            borderRadius: 1,
                            whiteSpace: 'pre-wrap',
                            textAlign: 'center',
                        }}
                    >
                        ¡Obten 3% de descuento en el total de tu compra pagando con transferencia bancaria!
                    </Typography>

            </Box>
        </React.Fragment>
    );
};

export default SeleccionMedioPago;
