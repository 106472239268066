import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Grid, useMediaQuery, Link, IconButton, Button, Divider, Box, Badge } from "@mui/material";
import Typography from "@mui/material/Typography";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import Prosillas from "../Image/logo.svg";
import carritoImg from "../Image/CARRITO.svg";
import Carrito from "./Compra/Carito";
import { useLocation } from "react-router-dom";
import { CartContext } from "../Context/CardContext"; // Ajusta la ruta si es necesario

// Estilo para el botón del logo
const LogoButton = styled(Link)`
  height: 130px;
  background-image: url(${Prosillas});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
`;

// Estilo para el contenedor de los iconos (carrito)
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ isSmallScreen }) =>
    isSmallScreen &&
    `
      position: absolute;
      top: 40px;
      right: 30px;
    `}
`;

const Datos = () => {
  const isSmallScreen = useMediaQuery("(max-width: 770px)");
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  // Consumir cantidadEnCarrito del contexto del carrito
  const { cantidadEnCarrito } = useContext(CartContext);

  const styles = {
    container: {
      backgroundColor: "#F5F4EE",
      color: "#49484A",
      padding: "10px 20px",
      textAlign: "left",
      position: "relative",
    },
    section: {
      display: "flex",
      alignItems: "center",
      justifyContent: isSmallScreen ? "center" : "flex-start",
      marginBottom: isSmallScreen ? "10px" : "0",
    },
    icon: {
      marginRight: "10px",
      color: "#49484A",
    },
    phoneNumber: {
      whiteSpace: "nowrap",
    },
  };

  // Función para abrir o cerrar el cajón del carrito
  const toggleDrawer = (newOpen) => () => {
    setIsOpen(newOpen);
  };

  const isConfirmationPage = location.pathname === "/confirmacion";

  const CarritoComponent = (
    <Box sx={{ width: 250 }} role="presentation">
      <Grid container style={{ textAlign: "center", justifyContent: "space-between", padding: "1.3rem" }}>
        <Typography
          variant="h3"
          sx={{
            fontSize: "1.5rem",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          Tu carro
        </Typography>
        <Button onClick={toggleDrawer(false)} sx={{ color: "black" }}>
          <CloseIcon />
        </Button>
      </Grid>
      <Divider />
      <Carrito toggleDrawer={toggleDrawer} />
    </Box>
  );

  return (
    <div style={styles.container}>
      <Grid container spacing={isSmallScreen ? 1 : 4} justifyContent="space-between" alignItems="center">
        {/* Logo */}
        <Grid item xs={12} sm={2} style={{ textAlign: isSmallScreen ? "center" : "flex-start" }}>
          <LogoButton href="/" />
        </Grid>

        {/* Número de teléfono */}
        <Grid item xs={12} sm={2} sx={{ display: isSmallScreen ? "none" : "block" }}>
          <div style={styles.section}>
            <a href="tel:+56988486087" style={{ display: "flex", alignItems: "center", color: "#49484A", textDecoration: "none" }}>
              <PhoneIcon style={styles.icon} />
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 400,
                  color: "#49484A",
                  ...styles.phoneNumber,
                }}
              >
                +569 8 848 6087
              </Typography>
            </a>
          </div>
        </Grid>

        {/* Correo electrónico */}
        <Grid item xs={12} sm={2} sx={{ display: isSmallScreen ? "none" : "block", textAlign: "center" }}>
          <div style={styles.section}>
            <a href="mailto:ventas.prosillas@gmail.com" style={{ display: "flex", alignItems: "center", color: "#49484A", textDecoration: "none" }}>
              <MailIcon style={styles.icon} />
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 400,
                  color: "#49484A",
                }}
              >
                ventas.prosillas@gmail.com
              </Typography>
            </a>
          </div>
        </Grid>

        {/* Icono del carrito */}
        <Grid item xs={12} sm={2}>
          {!isConfirmationPage && (
            <IconContainer isSmallScreen={isSmallScreen}>
              <IconButton
                onClick={toggleDrawer(true)}
                style={{ padding: 8 }}
                aria-label="Carrito"
              >
                <Badge
                  badgeContent={cantidadEnCarrito()}
                  color="secondary"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "#F0AD08",
                      color: "#ffffff",
                    },
                  }}
                >
                  <img
                    src={carritoImg}
                    alt="Carrito"
                    style={{ width: "30px", height: "30px", filter: "invert(1)" }}
                  />
                </Badge>
              </IconButton>
            </IconContainer>
          )}

          <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
            {CarritoComponent}
          </Drawer>
        </Grid>
      </Grid>
    </div>
  );
};

export default Datos;
