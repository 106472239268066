import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';

function Redirector() {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token_ws');

    if (token) {
      // Evitar reenvíos del mismo token
      if (sessionStorage.getItem(`processed_${token}`)) {
        // console.log('El token ya fue procesado.');
        return;
      }

      // Enviar el token al backend para procesarlo
      const formData = new FormData();
      formData.append('token_ws', token);

      fetch('https://prosillas.cl/api/Webpay/Confirmacion.php', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 'approved') {
            // Marcar el proceso como aprobado
            // console.log(data.message);
            sessionStorage.setItem('payment_status', 'approved');
            navigate('/aprobado-webpay');
          } else if (data.status === 'rejected') {
            // Marcar el proceso como rechazado
            // console.log(data.message);
            sessionStorage.setItem('payment_status', 'rejected');
            navigate('/rechazado-webpay');
          } else if (data.status === 'cancelled') {
            // Marcar el proceso como cancelado
            // console.log(data.message);
            sessionStorage.setItem('payment_status', 'cancelled');
            navigate('/cancelado-webpay');
          } else {
            // console.log(data.message);
            alert('Hubo un error en el proceso.');
          }
        })
        .catch((error) => {
          // console.error('Error en la confirmación:', error);
          alert('Hubo un error en la confirmación.');
        });
    } else {
      // Si no se recibe el token, considerar como cancelado
      sessionStorage.setItem('payment_status', 'cancelled');
      navigate('/cancelado-webpay');
    }
  }, [navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <Loader />
      <p>Redireccionando...</p>
    </div>
  );
}

export default Redirector;
