import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const ItemCount = ({ cantidad, handleRestar, handleSumar, handleAgregar, stock, costeAdicional }) => {
  return (
    <Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem'
      }}>
        <Button
          variant="contained"
          sx={{ 
            backgroundColor: '#49484A', 
            '&:hover': { backgroundColor: '#F0AD08' }, 
            padding: '4px 37px',
            fontSize: '0.875rem',
            minWidth: '35px'
          }}
          onClick={handleRestar}
        >
          -
        </Button>
        <Typography variant="body1" sx={{ fontSize: '1.5rem' }}>{cantidad}</Typography>
        <Button
          variant="contained"
          sx={{ 
            backgroundColor: '#49484A', 
            '&:hover': { backgroundColor: '#F0AD08' }, 
            padding: '4px 37px', 
            fontSize: '0.875rem',
            minWidth: '35px'     
          }}
          onClick={handleSumar}
        >
          +
        </Button>
      </Box>
      <Button
        variant="contained"
        sx={{ backgroundColor: '#49484A', '&:hover': { backgroundColor: '#F0AD08' }, marginTop: '1rem' }}
        onClick={handleAgregar}
      >
        Agregar al carrito
      </Button>
      {stock < 10 ? (
        <Typography variant="body2" color="error" sx={{ marginTop: '0.5rem', fontFamily: 'Poppins, sans-serif', fontWeight: 400}}>
          Últimas unidades ({stock} disponibles)
        </Typography>
      ) : (
        <Typography variant="body2" color="textPrimary" sx={{ marginTop: '0.5rem', marginLeft: '0.4rem', fontFamily: 'Poppins, sans-serif', fontWeight: 400}}>
          ({stock} unidades disponibles)
        </Typography>
      )}

      {costeAdicional > 0 && (
      <Typography 
        variant="body2" 
        color="warning" 
        sx={{ 
          fontFamily: 'Poppins, sans-serif', 
          fontWeight: 400, 
          marginTop: '0.5rem', 
          textAlign: 'justify', 
          textJustify: 'inter-word',
          '&:last-of-type': {
            textAlign: 'left'
          }
        }}
      >
        * El envío de 2 o más unidades posee un recargo adicional calculado al momento de la compra.
      </Typography>

      )}
    </Box>
  );
};

export default ItemCount;
