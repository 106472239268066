import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const toCapital = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatPrice = (price) => {
  const numericPrice = Number(price);
  return isNaN(numericPrice)
    ? 'Precio no disponible'
    : numericPrice.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
};

const Item = ({ producto }) => {
  const [imageSrc, setImageSrc] = useState(() =>
    `${producto.imagen}`
  );
  
  const handleImageError = () => {
    setImageSrc('https://prosillas.cl/images/Imagen_no_disponible.jpg');
  };

  const titleToSlug = (title) => {
    return title.toLowerCase().replace(/ /g, '-');
  };
  

  return (
    <Card
      component={Link}
      to={`/producto/${titleToSlug(producto.titulo)}/${producto.id}`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        height: '100%',
        padding: '1rem',
        textDecoration: 'none',
        color: 'inherit',
        '@media (max-width: 599px)': {
          padding: '0.5rem',
        },
      }}
    >
      <CardMedia
        component="img"
        image={imageSrc}
        alt={`Image of ${producto.titulo}`}
        onError={handleImageError}
        sx={{
          height: '180px',
          objectFit: 'contain',
          '@media (max-width: 599px)': {
            height: '120px',
          },
        }}
      />
      <CardContent
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '1rem 0',
          '@media (max-width: 599px)': {
            padding: '0.5rem 0',
          },
        }}
      >
        <div>
          <Typography
            variant="h6"
            component="h4"
            sx={{
              fontWeight: 400,
              textAlign: 'justify',
              textJustify: 'inter-word',
              fontSize: '1.25rem',
              fontFamily: 'Outfit, sans-serif',
              '@media (max-width: 599px)': {
                fontSize: '1rem',
              },
            }}
          >
            {producto.titulo}
          </Typography>
          <Typography
            sx={{
              fontSize: '1rem',
              fontFamily: 'Outfit, sans-serif',
              '@media (max-width: 599px)': {
                fontSize: '0.875rem',
              },
            }}
          >
            {`SKU: ${producto.modelo}`}
          </Typography>
          <Typography
            sx={{
              fontSize: '1rem',
              fontFamily: 'Outfit, sans-serif',
              '@media (max-width: 599px)': {
                fontSize: '0.875rem',
              },
            }}
          >
            Precio: {formatPrice(producto.precio)}
          </Typography>
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontFamily: 'Outfit, sans-serif',
              '@media (max-width: 599px)': {
                fontSize: '0.75rem',
              },
            }}
          >
            Categoría: {toCapital(producto.categoria)}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default Item;
