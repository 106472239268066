import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Nosotros = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontSize: '2rem',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 'bold',
            marginBottom: '2rem',
          }}
        >
          ¡Somos Prosillas!
        </Typography>
        
        <Typography 
          variant="body1" 
          paragraph 
          sx={{  
            fontFamily: 'Poppins, sans-serif', 
            fontWeight: 400, 
            textAlign: 'justify', 
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left'
            }
          }}
        >
          Somos una tienda especializada en sillas de oficina, enfocada en ofrecer soluciones ergonómicas, cómodas y funcionales para profesionales que buscan mejorar su espacio de trabajo. Con más de 10 años de experiencia en el mercado chileno, nuestra tienda online está comprometida con ofrecer productos de alta calidad para oficinas, hogares y para el Estado, adaptados a las necesidades de cada cliente.
        </Typography>
        <Typography 
          variant="body1" 
          paragraph 
          sx={{ 
            fontFamily: 'Poppins, sans-serif', 
            fontWeight: 400, 
            textAlign: 'justify', 
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left'
            }
          }}
        >
          Nos especializamos en sillas ergonómicas, sillas ejecutivas, sillas para oficina, y mobiliario de oficina en general. Sabemos que el confort y la productividad son esenciales en el entorno laboral, por lo que trabajamos con materiales de primera calidad, con diseños innovadores que garantizan una postura saludable y un entorno cómodo para tu jornada diaria. Además, ofrecemos sillas de oficina con respaldo ajustable, apoyabrazos, y mecanismos de inclinación, ideales para oficinas tanto en casa como en espacios profesionales.
        </Typography>

        <Typography 
          variant="body1" 
          paragraph 
          sx={{ 
            fontFamily: 'Poppins, sans-serif', 
            fontWeight: 400, 
            textAlign: 'justify', 
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left'
            }
          }}
        >
          En nuestra tienda online, también podrás encontrar escritorios, mesas, y muebles para oficina que complementan perfectamente cualquier entorno laboral. Nos enorgullece apoyar a las pequeñas empresas y emprendedores locales de Chile, ofreciendo productos elaborados por manos chilenas, con un enfoque en la sostenibilidad y la responsabilidad ambiental. Todos nuestros productos son seleccionados cuidadosamente para asegurarnos de que ofrezcan el mejor rendimiento a largo plazo.
        </Typography>   

        <Typography 
          variant="body1" 
          paragraph 
          sx={{ 
            fontFamily: 'Poppins, sans-serif', 
            fontWeight: 400,
            textAlign: 'justify', 
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left'
            }
          }}
        >
          Desde Maipú, en la Región Metropolitana, preparamos y enviamos cada pedido con dedicación y atención al detalle, buscando que cada cliente reciba sus productos en perfectas condiciones y con la mejor atención. Nos apasiona ofrecer un servicio personalizado, cercano y eficiente, porque sabemos que tu satisfacción es nuestra prioridad.
        </Typography>

        <Typography 
          variant="body1" 
          paragraph 
          sx={{ 
            fontFamily: 'Poppins, sans-serif', 
            fontWeight: 400,
            textAlign: 'justify', 
            textJustify: 'inter-word',
            '&:last-of-type': {
              textAlign: 'left'
            }
          }}
        >
          Gracias por confiar en nosotros. En Prosillas cada compra es un paso más para apoyar a los emprendedores chilenos y mejorar tu experiencia laboral con el mejor mobiliario de oficina.
        </Typography>
      </Box>
    </Container>
  );
};

export default Nosotros;
