import React, { useRef } from 'react';
import { useForm } from "react-hook-form";
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import axios from 'axios';
import { validarRut } from '../Utils/validarRut';
import ReCAPTCHA from "react-google-recaptcha";

const Contacto = () => {
  const { register, handleSubmit, formState: { errors, isValid }, reset, setValue, getValues } = useForm({
    mode: 'onChange'
  });

  const [mensajeEnviado, setMensajeEnviado] = React.useState(false);
  const [rutInvalido, setRutInvalido] = React.useState(false);
  const [recaptchaValue, setRecaptchaValue] = React.useState(null);

  const recaptchaRef = useRef(null);

  // Función para formatear el RUT
  const formatRut = (rut) => {
    if (!rut) return '';
    rut = rut.replace(/[^0-9kK]/g, '');
    rut = rut.replace(/k/g, 'K');
    return rut.length > 1 ? `${rut.slice(0, -1)}-${rut.slice(-1)}` : rut;
  };

  const handleRutChange = (event) => {
    const formattedRut = formatRut(event.target.value);
    if (validarRut(formattedRut)) {
      setValue('rut', formattedRut);
      setRutInvalido(false);
    } else {
      setRutInvalido(true);
    }
  };

  const enviar = async (data) => {
    if (!validarRut(getValues('rut'))) {
      setRutInvalido(true);
      return;
    }

    // Validar que el teléfono tenga exactamente 8 dígitos
    if (data.telefono.length !== 8) {
      return;
    }

    try {
      const response = await axios.post('https://prosillas.cl/api/correo.php', {
        ...data,
        recaptchaValue
      });
      setMensajeEnviado(true);
      reset();
      setRecaptchaValue(null);
      recaptchaRef.current.reset();
    } catch (error) {
      // console.error('Error:', error);
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 7 }}>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography variant="h4" component="h1" gutterBottom
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 700,
            }}
          >
            Contacto
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(enviar)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 7 }}>
            <TextField 
              label="Ingresa tu nombre"
              variant="outlined"
              {...register("nombre", { required: "Este campo es obligatorio" })}
              fullWidth
              error={!!errors.nombre}
              helperText={errors.nombre?.message}
              InputProps={{
                sx: {
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 400,
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                }
              }}
              InputLabelProps={{
                sx: {
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 400,
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                }
              }}
            />
            <TextField 
              label="Ingresa tu RUT"
              variant="outlined"
              {...register("rut", { required: "Este campo es obligatorio" })}
              fullWidth
              error={!!errors.rut || rutInvalido}
              helperText={rutInvalido ? "RUT no válido" : errors.rut?.message}
              onChange={handleRutChange}
              InputProps={{
                sx: {
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 400,
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                }
              }}
              InputLabelProps={{
                sx: {
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 400,
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                }
              }}
            />
            <TextField 
              label="Ingresa tu e-mail"
              variant="outlined"
              type="email"
              {...register("email", { 
                required: "Este campo es obligatorio",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "El email debe tener el formato correcto"
                }
              })}
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
              InputProps={{
                sx: {
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 400,
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                }
              }}
              InputLabelProps={{
                sx: {
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 400,
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                }
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ mr: 1 }} >
                +569
              </Typography>
              <TextField 
                label="Ingresa tu celular"
                variant="outlined"
                type="tel"
                {...register("telefono", {
                  required: "Este campo es obligatorio",
                  minLength: {
                    value: 8,
                    message: "El número debe tener exactamente 8 dígitos"
                  },
                  maxLength: {
                    value: 8,
                    message: "El número debe tener exactamente 8 dígitos"
                  },
                  pattern: {
                    value: /^\d{8}$/,
                    message: "El número debe tener exactamente 8 dígitos"
                  }
                })}
                fullWidth
                error={!!errors.telefono}
                helperText={errors.telefono?.message}
                InputProps={{
                  sx: {
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                  }
                }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                  }
                }}
              />  
            </Box>
            <TextField 
              label="Ingresa tu mensaje"
              variant="outlined"
              {...register("mensaje", { required: "Este campo es obligatorio" })}
              multiline
              rows={4}
              fullWidth
              error={!!errors.mensaje}
              helperText={errors.mensaje?.message}
              InputProps={{
                sx: {
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 400,
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                }
              }}
              InputLabelProps={{
                sx: {
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 400,
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                }
              }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LdH4r4qAAAAANU3bMgG7BFiLRaMrGjfsdgAEWS-"
                onChange={handleRecaptchaChange}
              />
            </Box>

            <Button 
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid || rutInvalido || (getValues('telefono')?.length !== 8) || !recaptchaValue}
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 400,
              }}
            >
              Enviar
            </Button>
          </Box>
        </form>
        {mensajeEnviado && (
          <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Typography variant="h6" color="success.main">
              ¡Tu consulta ha sido enviada exitosamente!
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Contacto;
