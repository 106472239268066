import React from 'react'; 
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import PlaceIcon from '@mui/icons-material/Place'; // Importa el ícono de ubicación

// Importa los logos
import IsotipoProsillas from '../Image/Footer/IsotipoProsillas.svg';
import TwitterLogo from '../Image/Footer/twitter.png'; 
import FacebookLogo from '../Image/Footer/facebook.png'; 
import InstagramLogo from '../Image/Footer/instagram.png'; 
import WebPayLogo from '../Image/Footer/webpay-cl.png';
import MercadoPagoLogo from '../Image/Footer/mercadopago.svg';
import convenioMujer from '../Image/Footer/convenio.jpg';

const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); 

  return (
    <Box mt={4} py={3} px={2} bgcolor="#49484A" color="black" boxShadow={2}>
      {/* Contenedor principal del footer */}
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        
        {/* Imagen de Convenio Mujer en la izquierda */}
        <Grid 
          item 
          xs={12} 
          sm={6} 
          md={3} 
          textAlign="center" 
        >
          <img 
            src={convenioMujer} 
            alt="Convenio Mujer" 
            style={{ maxWidth: '200px', width: '100%' }} 
          />
        </Grid>

        {/* Sección del logo de Milockers y redes sociales en el centro */}
        <Grid 
          item 
          xs={12} 
          sm={6} 
          md={3} 
          textAlign={isSmallScreen ? 'center' : 'center'}
          display="flex" 
          flexDirection="column" 
          alignItems="center"
        >
          {/* Logo de Milockers */}
          <Box mb={2}>
            <img src={IsotipoProsillas} alt="Milockers Logo" height="120" />
          </Box>

          {/* Íconos de redes sociales con inversión de colores */}
          <Box display="flex" justifyContent="center">
            <a href="https://twitter.com/ProsillasS" target="_blank" rel="noopener noreferrer">
              <img 
                src={TwitterLogo} 
                alt="Twitter" 
                height="30" 
                style={{ marginRight: '10px', filter: 'invert(100%)' }} // Invertir colores
              />
            </a>
            <a href="https://www.facebook.com/Prosillas-Spa-664444757286996/?modal=admin_todo_tour" target="_blank" rel="noopener noreferrer">
              <img 
                src={FacebookLogo} 
                alt="Facebook" 
                height="30" 
                style={{ marginRight: '10px', filter: 'invert(100%)' }} // Invertir colores
              />
            </a>
            <a href="https://www.instagram.com/prosillas.spa/" target="_blank" rel="noopener noreferrer">
              <img 
                src={InstagramLogo} 
                alt="Instagram" 
                height="32" 
                style={{ marginRight: '10px', filter: 'invert(100%)' }} // Invertir colores
              />
            </a>
          </Box>
        </Grid>

        {/* Sección de medios de pago en la derecha */}
        <Grid 
          item 
          xs={12} 
          sm={6} 
          md={3} 
          textAlign="center"
        >
          <Box>
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 700,
                textAlign: 'center',
              }}
            >
              Medios de Pago
            </Typography>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '32px', // Espaciado entre imágenes
              }}
            >
              <img
                src={WebPayLogo}
                alt="WebPay"
                style={{ maxWidth: '150px', width: '100%' }}
              />
              <img
                src={MercadoPagoLogo}
                alt="MercadoPago"
                style={{ maxWidth: '150px', width: '100%' }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Información de ubicación */}
      <Box textAlign="center" mt={2}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box display="flex" alignItems="center" mb={2}>
            <PlaceIcon 
              style={{ marginRight: '8px', color: '#F5F4EE' }} 
            />
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 700,
                color: 'black',
                textAlign: 'center',
              }}
            >
              Oficina: Presidente Juan Antonio Rios 33 Oficina 3, Santiago, Región Metropolitana
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <PlaceIcon 
              style={{ marginRight: '8px', color: '#F5F4EE' }} 
            />
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 700,
                color: 'black',
                textAlign: 'center',
              }}
            >
              Bodega: Av. Lo Espejo 860, Maipú, Región Metropolitana. Bodega 38. (Visita con previa coordinación)
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Derechos reservados y enlaces */}
      <Box textAlign="center" mt={2}>
        <Typography variant="body2" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
          © 2025 Prosillas. All rights reserved.
          <span style={{ marginLeft: '10px' }}>
            <Link to="/terminos" style={{ color: 'inherit', textDecoration: 'none' }}>
              Términos y condiciones
            </Link>
          </span>
          <span style={{ marginLeft: '10px' }}>
            <Link to="/politicas" style={{ color: 'inherit', textDecoration: 'none' }}>
              Política de cambios y devoluciones
            </Link>
          </span>
        </Typography>

        {/* Mensaje adicional en pantallas grandes */}
        {!isSmallScreen && (
          <Typography variant="body2" style={{ marginTop: '8px', fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
            Hecho con 💺 por Prosillas
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Footer;
