import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import Prosillas from '../Image/LogoProsillas-2.png';
import { Grid, TextField, useMediaQuery, InputAdornment, IconButton, Popover, Dialog, DialogContent } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";

const LogoButton = styled(Link)`
  height: 130px;
  background-image: url(${Prosillas});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
`;

// Estilo para el contenedor de navegación
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #F0AD08;
  color: white;
`;

// Estilo para el botón de cancelar (cerrar menú)
const CancelButton = styled(Button)`
  && {
    color: white;
    margin: 0 1rem;
    text-transform: none;
    font-size: 20px;
  }
`;

// Estilo para el menú de navegación (oculto en pantallas pequeñas)
const Menu = styled.div`
  display: flex;
  @media (max-width: 769px) {
    display: none;
  }
`;

// Estilo para los ítems del menú (botones)
const MenuItem = styled(Button)`
  && {
    color: #37424F;
    margin: 0 1rem;
    text-transform: none;
    font-size: 20px;
  }
`;

// Estilo para el icono de menú (hamburguesa) (visible en pantallas pequeñas)
const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  margin-right: auto;

  @media (max-width: 769px) {
    display: block;
  }
`;

// Estilo para el contenedor de contacto en el navbar
const ContactContainer = styled.div`
  display: flex; // Mostrar siempre
  align-items: center;
  margin-left: auto; // Mover a la derecha

  // Ocultar el texto en pantallas menores a 480px
  @media (max-width: 480px) {
    span {
      display: none; // Ocultar el texto
    }
  }

  // Ocultar íconos en pantallas más grandes (769px)
  @media (min-width: 769px) {
    display: none; // Ocultar el contenedor de contacto
  }

  // Estilo para el contenedor en pantallas menores a 769px
  @media (max-width: 768px) {
    flex-direction: row; // Alinear íconos en una fila
    justify-content: flex-start; // Alinear a la izquierda
    gap: 10px; // Espaciado entre los íconos
  }
`;

// Estilo para el número de teléfono
const PhoneNumber = styled.span`
  color: white;
  margin-right: 20px;
  white-space: nowrap; // Asegura que el texto no se divida
`;

const capitalizeWords = (text) => {
  const exceptions = ['de', 'del', 'la', 'el', 'y', 'o', 'en', 'con', 'a', 'por', 'para'];
  return text
    .toLowerCase()
    .split(' ')
    .map((word, index) => 
      exceptions.includes(word) && index !== 0
        ? word
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(' ');
};

const Navbar = ({ fixed, searchText, setSearchText }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [, setProductos] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const isSmallScreen = useMediaQuery('(max-width: 770px)');
  const [openDialog, setOpenDialog] = useState(false);


  useEffect(() => {
    const fetchProductos = async () => {
      const response = await fetch('https://prosillas.cl/api/productos.php');
      const data = await response.json();
      setProductos(data.data);
      const allCategories = [...new Set(
        data.data.map((prod) => capitalizeWords(prod.categoria.trim()))
      )];

      setCategorias(allCategories);
      // console.log(allCategories)
    }
    fetchProductos()
  }, [])

  // Función para abrir o cerrar el cajón del menú
  const toggleMenuDrawer = (newOpen) => () => {
    setMenuOpen(newOpen);
  };

  // Verifica si la página actual es la de confirmación
  const isConfirmationPage = location.pathname === '/confirmacion';

  // Componente para la lista de navegación en el cajón del menú
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleMenuDrawer(false)}>
      <List>
        {[
          { text: 'Inicio', href: '/' },
          { text: 'Productos', href: '/productos' },
          { text: 'Nosotros', href: '/nosotros' },
          { text: 'Contacto', href: '/contacto' },
        ].map(({ text, href }) => (
          <ListItem key={href} disablePadding>
            <ListItemButton onClick={() => (window.location.href = href)}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  const pathname = location.pathname;

  const handleSearch = (value) => {
    if (value.trim() !== "") {
      navigate(`/productos?search=${value}`);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const inputRef = useRef(null);

  const isProductosPage = location.pathname === "/productos";


  useEffect(() => {
    if (openDialog) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100); // Pequeño retraso para asegurar que el Dialog está completamente montado
    }
  }, [openDialog]);

  return (
    <Nav fixed={fixed}>
      {/* Renderiza un diseño diferente si estamos en la página de confirmación */}
      {isConfirmationPage ? (
        <>
          <Grid item xs={12} sm={2} style={{ textAlign: isSmallScreen ? 'center' : 'flex-start' }}>
            <LogoButton href="/" />
          </Grid>
          <CancelButton
            variant="outlined"
            onClick={() => window.location.href = '/'}
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 400,
              textAlign: 'center',
              textJustify: 'inter-word',
              backgroundColor: '#37424F',
              '&:hover': {
                backgroundColor: '#4A5968',
              },
            }}
          >
            Seguir comprando
          </CancelButton>
        </>
      ) : (
        <>
          <Hamburger onClick={toggleMenuDrawer(true)}>
            <MenuIcon style={{ color: 'white' }} />
          </Hamburger>
          <Menu style={{ width: "100%" }}>
            {/* Menú de navegación visible en pantallas grandes */}
            <MenuItem
              component={Link}
              to="/"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 400,
                color: 'black',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#49484A',
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Inicio
            </MenuItem>
            <MenuItem sx={{
                backgroundColor: '#f0ad08',
                borderStyle: "none",
                boxShadow: "none",
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 400,
                fontSize: '1.01rem',
                color: 'black',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#49484A',
                  color: 'white'
                },
                textDecoration: 'none'
              }} aria-describedby={"productos"} variant="contained" onClick={(e) => setAnchorEl(e.currentTarget)}>
              Productos
            </MenuItem>
            <Popover
              id={"productos"}
              open={open}
              anchorEl={anchorEl}
              onClose={() => { setAnchorEl(null) }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <div style={{display: "flex", flexDirection: "column", padding: "10px"}}>
              <Button sx={{fontFamily: 'Poppins, sans-serif',
                    fontWeight: 400, color: 'black',
                    padding: '10px 20px', textDecoration: 'none',
                    '&:hover': {backgroundColor: '#49484A', color: 'white'}}}
                   href={`/productos`}>Todos</Button>

                {categorias.map((categoria) => (
                  <Button sx={{fontFamily: 'Poppins, sans-serif',
                    fontWeight: 400, color: 'black',
                    padding: '10px 20px', textDecoration: 'none',
                    '&:hover': {backgroundColor: '#49484A', color: 'white'}}}
                   href={`/productos?category=${categoria}`}>{categoria}</Button>
                ))}
              </div>
            </Popover>

            <MenuItem
              component={Link}
              to="/nosotros"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 400,
                color: 'black',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#49484A',
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Nosotros
            </MenuItem>
            <MenuItem
              component={Link}
              to="/contacto"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 400,
                color: 'black',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#49484A',
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Contacto
            </MenuItem>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                visibility: pathname.includes("/productos") ? "hidden" : "visible",
              }}
            >
              <Box
                component="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  const searchValue = e.target[0].value;
                  handleSearch(searchValue);
                }}
              >
                <TextField
                  id="buscar-productos"
                  label="Buscar Productos"
                  placeholder="Buscar..."
                  variant="outlined"
                  required
                  sx={{
                    backgroundColor: "white",
                    borderRadius: 1,
                    "& .MuiInputBase-root": {
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                      textAlign: "justify",
                      textJustify: "inter-word",
                    },
                    "& .MuiInputLabel-root": {
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                      textAlign: "justify",
                      textJustify: "inter-word",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            const form = e.target.closest("form");
                            const searchValue = form ? form[0].value : "";
                            handleSearch(searchValue);
                          }}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Menu>

          {/* Sección de contacto en el Navbar (visible solo en modo responsivo) */}
          <ContactContainer>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                // visibility: pathname.includes("/productos") ? "hidden" : "visible",
              }}
            >
              <IconButton onClick={() => setOpenDialog(true)}>
                <SearchIcon sx={{ color: "white" }} />
              </IconButton>
            </Box>

            <a href="tel:+56988486087" style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
              <PhoneIcon style={{ marginRight: '5px', color: 'white' }} />
              <PhoneNumber>+569 8 848 6087</PhoneNumber>
            </a>
            <a href="mailto:ventas.prosillas@gmail.com" style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
              <MailIcon style={{ marginRight: '5px', color: 'white' }} />
              <span style={{ color: 'white' }}>ventas.prosillas@gmail.com</span>
            </a>

          {/* Dialog con el Buscador */}
          <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            sx={{
              "& .MuiDialog-paper": {
                position: "absolute",
                top: "15%",
                transform: "translateY(-20%)",
              },
            }}
          >
            <DialogContent>
              <Box
                component="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  const searchValue = e.target[0].value;
                  handleSearch(searchValue);
                  setOpenDialog(false);
                }}
              >
                {/* Dependiendo de la ruta, mostramos un texto diferente */}
                {isProductosPage ? (
                  <TextField
                    id="buscar-productos"
                    placeholder="Buscar productos..."
                    variant="standard"
                    required
                    fullWidth
                    inputRef={inputRef}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 400,
                        fontSize: '16px',
                        padding: '10px',
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              const form = e.target.closest('form');
                              const searchValue = form ? form[0].value : '';
                              handleSearch(searchValue);
                            }}
                            sx={{
                              backgroundColor: '#F0AD08',
                              borderRadius: '50%',
                              padding: '10px',
                              color: '#ffffff',
                              '&:hover': {
                                backgroundColor: '#F0AD08',
                              },
                            }}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <TextField
                    id="buscar-productos"
                    placeholder="Buscar productos..."
                    variant="standard"
                    required
                    fullWidth
                    inputRef={inputRef}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 400,
                        fontSize: '16px',
                        padding: '10px',
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              const form = e.target.closest("form");
                              const searchValue = form ? form[0].value : "";
                              handleSearch(searchValue);
                              setOpenDialog(false);
                            }}
                            sx={{
                              backgroundColor: '#F0AD08',
                              borderRadius: '50%',
                              padding: '10px',
                              color: '#ffffff',
                              '&:hover': {  
                                backgroundColor: '#F0AD08',
                              },
                            }}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Box>
            </DialogContent>
          </Dialog>
          
          </ContactContainer>

          {/* Cajón del menú */}
          <Drawer anchor="left" open={menuOpen} onClose={toggleMenuDrawer(false)}>
            {DrawerList}
          </Drawer>
        </>
      )
      }
    </Nav >
  );
};

export default Navbar;
